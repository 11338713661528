
import { mapGetters } from 'vuex'
export default {
  data: () => ({
    toggle: {
      width: 55,
      height: 30,
      margin: 2
    },
    settings: {
      required: {
        disabled: true,
        value: true
      },
      statistics: {
        disabled: false,
        value: true
      },
      social: {
        disabled: false,
        value: true
      },
      marketing: {
        disabled: false,
        value: true
      }
    }
  }),
  computed: {
    ...mapGetters({
      cookieAvailable: 'cookies/available'
    })
  },
  watch: {
    cookieAvailable: {
      immediate: true,
      handler(available) {
        if (available && Array.isArray(available)) {
          available.map((item) => this.$set(this.settings[item], 'value', true))

          Object.keys(this.settings)
            .filter((x) => !available.includes(x))
            .map((item) => this.$set(this.settings[item], 'value', false))
        }
      }
    }
  },
  methods: {
    accept() {
      const cookies = Object.keys(this.settings).filter(
        (key) => this.settings[key].value
      )

      this.$cookie.set('ts__cookie__consent', cookies, { path: '/' })

      this.$store.dispatch('cookies/setConsent', true)
      this.$store.dispatch('cookies/setAvailable', cookies)
    }
  }
}
