import dayjs from '~/utils/dayjs'

const initialState = () => {
  return {
    version: process.env.npm_package_version,
    // categories: [],
    uid: null,
    client: {},
    mobileLayout: ['xs', 'sm', 'md'],
    desktopLayout: ['lg'],
    loading: false,
    loadingText: null
  }
}

export const state = () => {
  return initialState()
}

export const mutations = {
  RESET_STATE(state, payload) {
    Object.assign(state, initialState())
  },
  HYDRATE(state, payload) {
    Object.assign(state, payload)
  },
  // SET_CATEGORIES(state, payload) {
  //   state.categories = payload
  // },
  SET_CLIENT(state, payload) {
    state.client = payload
  },
  SHOW_LOADING_OVERLAY(state, payload) {
    state.loadingText = payload
    state.loading = true
  },
  HIDE_LOADING_OVERLAY(state) {
    state.loading = false
    state.loadingText = null
  }
}

export const getters = {
  client: (state) => state.client,
  clientPhone: (state) =>
    state.client.phone ? `+${state.client.phone}` : null,
  // categories: (state) => state.categories,
  isAuthenticated: (state) => !!state.uid,
  mobileLayout: (state) => state.mobileLayout,
  desktopLayout: (state) => state.desktopLayout,
  loading: (state) => state.loading,
  loadingText: (state) => state.loadingText
}

export const actions = {
  async nuxtServerInit({ commit, dispatch }, { req, app, route }) {
    const referrerCookie = await app.$cookiz.get('ts__cookie__referer')
    const referrer = req.headers.referrer || req.headers.referer
    const query = route.query

    if (app.$config.WIDGET_DATEPICKER_ORG_ID) {
      await dispatch(
        'cookies/setWidgetOrgId',
        app.$config.WIDGET_DATEPICKER_ORG_ID
      )
    } else if (app.$config.WIDGET) {
      const widget = route.query['widget-event']

      if (widget) {
        await dispatch('cookies/setWidget', widget)
      }
    }

    if (
      !referrerCookie &&
      referrer &&
      // stop adding referrer on thnq page, because if ref was empty, we write to ref payment system url
      !['payment-hash'].includes(app.getRouteBaseName())
    ) {
      await app.$cookie.set('ts__cookie__referer', referrer, {
        maxAge: dayjs.duration({ hours: 4 }).asSeconds(),
        path: '/'
      })
    }

    const queryCookie = await app.$cookiz.get('ts__cookie__query')
    if (!queryCookie && Object.keys(query).length) {
      await app.$cookie.set('ts__cookie__query', query, { path: '/' })
    }

    const landingCookie = await app.$cookiz.get('ts__cookie__landing')
    if (!landingCookie) {
      await app.$cookie.set(
        'ts__cookie__landing',
        `${app.$config.WEBSITE_URL}${route.fullPath}`,
        { path: '/' }
      )
    }

    const cookies = await app.$cookiz.get('ts__cookie__consent')
    await dispatch('cookies/setConsent', !!cookies)
    if (cookies) {
      await dispatch('cookies/setAvailable', cookies)
    }

    await dispatch('FETCH_SESSION')
  },
  async FETCH_SESSION({ dispatch }) {
    try {
      const check = await this.$api.auth.use('data').check()
      if (check.uid) {
        dispatch('SET_CLIENT_ID', check.uid)
        const client = await this.$api.auth.use('data').client()
        dispatch('SET_CLIENT', client)
      } else {
        dispatch('SET_CLIENT_ID', null)
      }
      await this.$cookie.set('ts__session', check.session, { path: '/' })
      await dispatch('cookies/setSession', check.session)
      await dispatch('cart/CART_LOAD')
    } catch (e) {
      console.log(e)
    }
  },
  SET_CLIENT_ID(context, payload) {
    context.commit('HYDRATE', {
      uid: payload
    })
  },
  SET_CLIENT(context, payload) {
    context.commit('SET_CLIENT', payload)
  },
  SHOW_LOADING_OVERLAY(context, payload) {
    context.commit('SHOW_LOADING_OVERLAY', payload)
  },
  HIDE_LOADING_OVERLAY(context, payload) {
    context.commit('HIDE_LOADING_OVERLAY', payload)
  }
}
