import { render, staticRenderFns } from "./Index.vue?vue&type=template&id=359514e5&scoped=true&"
import script from "./Index.vue?vue&type=script&lang=js&"
export * from "./Index.vue?vue&type=script&lang=js&"
import style0 from "./Index.vue?vue&type=style&index=0&id=359514e5&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "359514e5",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CartEmpty: require('/home/ticketshoplv/public_html/platina.tour.tickets/components/Cart/Empty.vue').default,CartItem: require('/home/ticketshoplv/public_html/platina.tour.tickets/components/Cart/Item.vue').default,CartSummary: require('/home/ticketshoplv/public_html/platina.tour.tickets/components/Cart/Summary.vue').default})
