
import { mapGetters } from 'vuex'
import dayjs from '~/utils/dayjs'
import Timer from '@/mixins/timer'
import Modal from '@/components/elements/Modal'
import ModalMixin from '@/mixins/modal'
export default {
  components: {
    DatePicker: () =>
      process.client
        ? import('v-calendar/lib/components/date-picker.umd')
        : null
  },
  mixins: [Timer, ModalMixin],
  data() {
    return {
      pricezonesCounter: {},
      date: null
    }
  },
  fetchOnServer: false,
  async fetch() {
    try {
      if (this.widgetOrgId) {
        await this.fetchCalendar()
      } else {
        await this.fetchEvent(this.widget)
      }
    } catch (e) {}
  },

  head() {
    const i18nSeo = this.$nuxtI18nSeo()
    return {
      ...i18nSeo
    }
  },

  computed: {
    ...mapGetters({
      cartOpen: 'cart/open',
      event: 'event/event',
      events: 'event/events',
      nearestEventDate: 'event/nearestEventDate',
      widget: 'cookies/widget',
      widgetOrgId: 'cookies/widgetOrgId',
      mobileLayout: 'mobileLayout'
    }),
    locales() {
      const _locales = {}

      this.$config.available_languages.map((code) => {
        _locales[code] = this.$t('misc.langselector', code)
        return code
      })

      return _locales
    },
    isMobile() {
      return this.mobileLayout.includes(this.$mq)
    },
    dateFrom() {
      return this.event.date_from
        ? typeof this.event.date_from === 'string'
          ? this.event.date_from
          : this.event.date_from.date
        : false
    },
    dateTo() {
      return this.event.date_to && this.event.date_to !== '0000-00-00 00:00:00'
        ? typeof this.event.date_to === 'string'
          ? this.event.date_to
          : this.event.date_from.date
        : false
    },
    backgroundMain() {
      return this.event && this.event.avatar
        ? 'background: linear-gradient(to right bottom, rgba(38, 40, 49, 0.8), rgba(38, 40, 49, 0.8)),  url(' +
            this.posterImg +
            ') 50% center no-repeat'
        : ''
    },
    posterImg() {
      return this.event && this.event.avatar
        ? this.$config.CDN_URL +
            'event/' +
            this.event.id +
            '/' +
            this.event.avatar +
            '/403x570.jpg'
        : ''
    },
    availableDates() {
      if (!this.events.length) {
        return []
      }

      return this.events.map((event) => {
        return {
          start: new Date(event.event_date),
          end: new Date(event.event_date)
        }
      })
    }
  },

  watch: {
    $route: {
      immediate: true,
      handler() {
        this.$store.dispatch('cart/CART_LOAD')
      }
    },
    '$i18n.locale': {
      handler() {
        this.$fetch()
        this.$store.dispatch('cart/CART_LOAD')
      }
    }
  },

  mounted() {
    this.$store.dispatch('cart/TOGGLE_CART', false)
  },

  methods: {
    async dateChanged(d) {
      const date = dayjs(d || new Date()).format('YYYY-MM-DD')
      const selectedEvent = this.events.find(
        (event) => event.event_date === date
      )
      if (!this.$route.query.date || this.$route.query.date !== date) {
        this.$router.replace({ query: { ...this.$route.query, date } })
      }

      if (selectedEvent.id !== this.event.id) {
        await this.fetchEvent(selectedEvent.id)
      }
    },
    async fetchCalendar() {
      try {
        await this.$store.dispatch('event/fetchCalendar', {
          id: this.widgetOrgId
        })

        const dateFromQuery =
          this.$route.query.date || dayjs().format('YYYY-MM-DD')

        this.date = this.nearestEventDate(dateFromQuery)

        await this.dateChanged(this.date)
      } catch (e) {
        console.log(e)
      }
    },
    async fetchEvent(id) {
      await this.$store.dispatch('event/fetchEvent', {
        id
      })

      if (this.event.custom_terms) {
        this.$modal.show(
          Modal,
          {
            text: this.event.custom_terms,
            button: this.$t(`buttons.consent`)
          },
          {
            height: 'auto',
            width: this.modalWidthCalc(),
            adaptive: true,
            scrollable: true,
            clickToClose: false
          }
        )
      }
    }
  }
}
