import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _77b63cdc = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _1e836d06 = () => interopDefault(import('../pages/cart.vue' /* webpackChunkName: "pages/cart" */))
const _10128485 = () => interopDefault(import('../pages/categories/index.vue' /* webpackChunkName: "pages/categories/index" */))
const _275b48ce = () => interopDefault(import('../pages/cookies.vue' /* webpackChunkName: "pages/cookies" */))
const _7bcc9e42 = () => interopDefault(import('../pages/events/index.vue' /* webpackChunkName: "pages/events/index" */))
const _415ddff3 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _376f26f0 = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _cf595bda = () => interopDefault(import('../pages/payment/index.vue' /* webpackChunkName: "pages/payment/index" */))
const _56ba8c14 = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _ecbd5fee = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _ef4e2eca = () => interopDefault(import('../pages/restore/index.vue' /* webpackChunkName: "pages/restore/index" */))
const _2ad0380e = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _fc1a44a6 = () => interopDefault(import('../pages/widget/index.vue' /* webpackChunkName: "pages/widget/index" */))
const _3c55c28b = () => interopDefault(import('../pages/profile/tickets.vue' /* webpackChunkName: "pages/profile/tickets" */))
const _02a0d597 = () => interopDefault(import('../pages/widget/description.vue' /* webpackChunkName: "pages/widget/description" */))
const _d760c9c2 = () => interopDefault(import('../pages/categories/_cid/index.vue' /* webpackChunkName: "pages/categories/_cid/index" */))
const _35871c84 = () => interopDefault(import('../pages/events/_eid/index.vue' /* webpackChunkName: "pages/events/_eid/index" */))
const _62cc56e5 = () => interopDefault(import('../pages/groups/_id.vue' /* webpackChunkName: "pages/groups/_id" */))
const _52a0c4a4 = () => interopDefault(import('../pages/payment/_hash.vue' /* webpackChunkName: "pages/payment/_hash" */))
const _7a9ddfe7 = () => interopDefault(import('../pages/restore/_key.vue' /* webpackChunkName: "pages/restore/_key" */))
const _14a88cce = () => interopDefault(import('../pages/events/_eid/tickets.vue' /* webpackChunkName: "pages/events/_eid/tickets" */))
const _75ff9b94 = () => interopDefault(import('../pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'active',
  scrollBehavior,

  routes: [{
    path: "/en",
    component: _77b63cdc,
    name: "index___en"
  }, {
    path: "/ru",
    component: _77b63cdc,
    name: "index___ru"
  }, {
    path: "/en/cart",
    component: _1e836d06,
    name: "cart___en"
  }, {
    path: "/en/categories",
    component: _10128485,
    name: "categories___en"
  }, {
    path: "/en/cookies",
    component: _275b48ce,
    name: "cookies___en"
  }, {
    path: "/en/events",
    component: _7bcc9e42,
    name: "events___en"
  }, {
    path: "/en/login",
    component: _415ddff3,
    name: "login___en"
  }, {
    path: "/en/logout",
    component: _376f26f0,
    name: "logout___en"
  }, {
    path: "/en/payment",
    component: _cf595bda,
    name: "payment___en"
  }, {
    path: "/en/profile",
    component: _56ba8c14,
    name: "profile___en"
  }, {
    path: "/en/register",
    component: _ecbd5fee,
    name: "register___en"
  }, {
    path: "/en/restore",
    component: _ef4e2eca,
    name: "restore___en"
  }, {
    path: "/en/search",
    component: _2ad0380e,
    name: "search___en"
  }, {
    path: "/en/widget",
    component: _fc1a44a6,
    name: "widget___en"
  }, {
    path: "/ru/cart",
    component: _1e836d06,
    name: "cart___ru"
  }, {
    path: "/ru/categories",
    component: _10128485,
    name: "categories___ru"
  }, {
    path: "/ru/cookies",
    component: _275b48ce,
    name: "cookies___ru"
  }, {
    path: "/ru/events",
    component: _7bcc9e42,
    name: "events___ru"
  }, {
    path: "/ru/login",
    component: _415ddff3,
    name: "login___ru"
  }, {
    path: "/ru/logout",
    component: _376f26f0,
    name: "logout___ru"
  }, {
    path: "/ru/payment",
    component: _cf595bda,
    name: "payment___ru"
  }, {
    path: "/ru/profile",
    component: _56ba8c14,
    name: "profile___ru"
  }, {
    path: "/ru/register",
    component: _ecbd5fee,
    name: "register___ru"
  }, {
    path: "/ru/restore",
    component: _ef4e2eca,
    name: "restore___ru"
  }, {
    path: "/ru/search",
    component: _2ad0380e,
    name: "search___ru"
  }, {
    path: "/ru/widget",
    component: _fc1a44a6,
    name: "widget___ru"
  }, {
    path: "/en/profile/tickets",
    component: _3c55c28b,
    name: "profile-tickets___en"
  }, {
    path: "/en/widget/description",
    component: _02a0d597,
    name: "widget-description___en"
  }, {
    path: "/ru/profile/tickets",
    component: _3c55c28b,
    name: "profile-tickets___ru"
  }, {
    path: "/ru/widget/description",
    component: _02a0d597,
    name: "widget-description___ru"
  }, {
    path: "/en/categories/:cid",
    component: _d760c9c2,
    name: "categories-cid___en"
  }, {
    path: "/en/events/:eid",
    component: _35871c84,
    name: "events-eid___en"
  }, {
    path: "/en/groups/:id?",
    component: _62cc56e5,
    name: "groups-id___en"
  }, {
    path: "/en/payment/:hash",
    component: _52a0c4a4,
    name: "payment-hash___en"
  }, {
    path: "/en/restore/:key",
    component: _7a9ddfe7,
    name: "restore-key___en"
  }, {
    path: "/ru/categories/:cid",
    component: _d760c9c2,
    name: "categories-cid___ru"
  }, {
    path: "/ru/events/:eid",
    component: _35871c84,
    name: "events-eid___ru"
  }, {
    path: "/ru/groups/:id?",
    component: _62cc56e5,
    name: "groups-id___ru"
  }, {
    path: "/ru/payment/:hash",
    component: _52a0c4a4,
    name: "payment-hash___ru"
  }, {
    path: "/ru/restore/:key",
    component: _7a9ddfe7,
    name: "restore-key___ru"
  }, {
    path: "/en/events/:eid/tickets",
    component: _14a88cce,
    name: "events-eid-tickets___en"
  }, {
    path: "/ru/events/:eid/tickets",
    component: _14a88cce,
    name: "events-eid-tickets___ru"
  }, {
    path: "/en/:slug",
    component: _75ff9b94,
    name: "slug___en"
  }, {
    path: "/ru/:slug",
    component: _75ff9b94,
    name: "slug___ru"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
