import Vue from 'vue'

export default ({ app }) => {
  Vue.filter('currency', function (value, options) {
    const defaultOptions = {
      currency: 'EUR',
      prefix: '',
      minimumFractionDigits: 2
    }

    const _options = {
      ...defaultOptions,
      ...options,
      ...{
        currency: options.currency || defaultOptions.currency
      }
    }

    return Intl.NumberFormat(app.i18n.localeProperties.iso, {
      style: 'currency',
      currencyDisplay: 'code',
      ..._options
    })
      .format(!value || isNaN(value) ? 0 : value)
      .replace(/^(\D+)/, `${String(_options.prefix)} $1`)
      .trim()
  })

  Vue.filter('prettify', (value) => {
    const minutes = Math.floor(value / 60)
      .toString()
      .padStart(2, '0')
    const seconds = (value - minutes * 60).toString().padStart(2, '0')

    return minutes + ':' + seconds
  })

  Vue.filter('dateLocalize', (value) => {
    const date = new Date(value.replace(/-/g, '/'))

    return new Intl.DateTimeFormat('en-GB', {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    })
      .format(date)
      .split('/')
      .join('.')
      .split(',')
      .join('')
  })

  Vue.filter('dateToStr', (date) => {
    if (!date) {
      return date
    }
    const t = date.split(/[- :]/)
    return new Date(t[0], t[1] - 1, t[2], t[3] || 0, t[4] || 0, t[5] || 0)
  })

  Vue.filter('dateStr', (date) => {
    return app.i18n.d(date, 'longer') + ' (' + app.i18n.d(date, 'fullDay') + ')'
  })

  Vue.filter('dateStrNumeric', (date) => {
    return app.i18n.d(date, 'tickets')
  })

  Vue.filter('dateStrLong', (date) => {
    return (
      app.i18n.d(date, 'tickets') + ' (' + app.i18n.d(date, 'fullDay') + ')'
    )
  })
}
