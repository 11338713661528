import { render, staticRenderFns } from "./default.vue?vue&type=template&id=76ff1d55&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ElementsOverlay: require('/home/ticketshoplv/public_html/platina.tour.tickets/components/elements/Overlay.vue').default,CookiesModal: require('/home/ticketshoplv/public_html/platina.tour.tickets/components/cookies/Modal.vue').default,LayoutHeader: require('/home/ticketshoplv/public_html/platina.tour.tickets/components/layout/Header.vue').default,LayoutFooter: require('/home/ticketshoplv/public_html/platina.tour.tickets/components/layout/Footer.vue').default,Cart: require('/home/ticketshoplv/public_html/platina.tour.tickets/components/Cart/Index.vue').default,CartMobile: require('/home/ticketshoplv/public_html/platina.tour.tickets/components/Cart/Mobile.vue').default})
